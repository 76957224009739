import React from 'react';
import {Link} from "react-router-dom";
import Themes from '../themes/Themes';
import "./TopMenu.scss";

function TopMenu() {
    const contactForm = "1ddd12cb-214d-458a-8bdf-8d4b66a4be35";

    return (
        <div className="top-menu">
            <Themes/>
            {/* <a className={'top-menu-icon'} target="_blank" href="https://discord.com"><div className="discord"></div></a> */}
            <Link to={{
                pathname: `/`
            }}>
                Scripts
            </Link>
            <a href="https://www.youtube.com/@iOgGaming" target="_blank">
                Subscribe
            </a>
            <Link to={{
                pathname: `/info`
            }}>
                Info
            </Link>
            {contactForm ? <Link to={{pathname: `/contact`}}>Contact</Link> : null}
        </div>
    );
}

export default TopMenu;
